import {
  ContextFilterState,
  FiltersBarState,
  FilterDisplayConfig,
  FilteredDashboards,
  FilterBarComponentsState,
} from '../../modules/filter-bar/filters-bar.types'
import {
  isEqualFilterSelections,
  emptyContextFilterState,
  getFiltersOjectFromURL,
  isEmptyFilterComponentsState,
  emptyFilterComponentsState,
  isEmptyContextFilterSubState,
} from '../../modules/filter-bar/filters.api'
import { createSlice } from '@reduxjs/toolkit'
import _, { rest } from 'lodash'
import { REQUEST_STATUS, stateReducer } from '../../webapp-lib/pathspot-react'

export const initialState: FiltersBarState = {
  buttonVisible: false,
  barVisible: true,
  touched: false,
  filtersState: { ...emptyContextFilterState },
  contextFiltersStates: Object.keys(FilteredDashboards).map((key: any) => {
    return { ...emptyContextFilterState, context: key }
  }),
  defaultComponentStates: Object.fromEntries(
    Object.keys(FilteredDashboards).map((key: any) => {
      return [key, emptyFilterComponentsState]
    })
  ),
}
type args = { type?: string; [key: string]: any }

export const filtersbarKey = 'filtersbar'

export enum FiltersBarActions {
  initializeFilterBarState = 'initializeFilterBarState',
  setFilterBarState = 'setFilterBarState',
  setFiltersPrivileges = 'setFiltersPrivileges',
  reconcileFilterStateWithResponse = 'reconcileFilterStateWithResponse',
  setFilterSelections = 'setFilterSelections',
  setChangedFilterSelections = 'setChangedFilterSelections',
  toggleFilterVisibility = 'toggleFilterVisibility',
  toggleFilterButtonVisibility = 'toggleFilterButtonVisibility',
}
export const FiltersBarDispatch = {
  [FiltersBarActions.initializeFilterBarState]: `${filtersbarKey}/${FiltersBarActions.initializeFilterBarState}`,
  [FiltersBarActions.setFilterBarState]: `${filtersbarKey}/${FiltersBarActions.setFilterBarState}`,
  [FiltersBarActions.setFilterSelections]: `${filtersbarKey}/${FiltersBarActions.setFilterSelections}`,
  [FiltersBarActions.setChangedFilterSelections]: `${filtersbarKey}/${FiltersBarActions.setChangedFilterSelections}`,
  [FiltersBarActions.toggleFilterButtonVisibility]: `${filtersbarKey}/${FiltersBarActions.toggleFilterButtonVisibility}`,
  [FiltersBarActions.toggleFilterVisibility]: `${filtersbarKey}/${FiltersBarActions.toggleFilterVisibility}`,
  [FiltersBarActions.setFiltersPrivileges]: `${filtersbarKey}/${FiltersBarActions.setFiltersPrivileges}`,
  [FiltersBarActions.reconcileFilterStateWithResponse]: `${filtersbarKey}/${FiltersBarActions.reconcileFilterStateWithResponse}`,
}

export const filtersBarSlice = createSlice({
  name: filtersbarKey,
  initialState,
  reducers: {
    [FiltersBarActions.initializeFilterBarState]: (state: FiltersBarState, action: any) => {
      //console.log('Setting full filter bar state to: ', { ...action.payload })
      state = { ...initialState }
      //console.log('State after setting full state is: ', _.cloneDeep(state))
      return state
    },
    [FiltersBarActions.setFilterBarState]: (state: FiltersBarState, action: any) => {
      //console.log('Setting full filter bar state to: ', { ...action.payload })
      state = { ...state, ...action.payload }
      //console.log('State after setting full state is: ', _.cloneDeep(state))
      return state
    },
    [FiltersBarActions.setFilterSelections]: (state: FiltersBarState, action: any) => {
      //console.log('State in setFilterSelections is initially: ', _.cloneDeep(state))
      //console.log('Setting filters selections to: ', _.cloneDeep({ ...action.payload }))
      state.filtersState.selections = { ...action.payload }
      //console.log('Return state is: ', _.cloneDeep({ ...state }))
      return state
    },
    [FiltersBarActions.setFiltersPrivileges]: (state: FiltersBarState, action: any) => {
      state.filtersState.privileges = { ...action.payload }
      return state
    },
    [FiltersBarActions.toggleFilterButtonVisibility]: (state: FiltersBarState, action: any) => {
      state.buttonVisible = state.buttonVisible ? false : true
      return state
    },
    [FiltersBarActions.toggleFilterVisibility]: (state: FiltersBarState, action: any) => {
      state.barVisible = state.barVisible ? false : true
      return state
    },
  },
})

export const { setFilterBarState } = filtersBarSlice.actions

export default filtersBarSlice.reducer
